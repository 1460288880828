import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class Globals {

    constructor(
      private httpClient: HttpClient,
    ) {}

    public url = 'https://pyres.adamentis.fr:8000';
    public toggle = {
      element1State: false,
      pipe1State: false,
      pipe1Direction: false,
      pipe2State: false,
      pipe2Direction: false,
    };

    public convertToSegments(points: { x: number, y: number }[]) {
        const segments = [];
        
        for (let i = 0; i < points.length - 1; i++) {
          const x1 = points[i].x;
          const y1 = points[i].y;
          const x2 = points[i + 1].x;
          const y2 = points[i + 1].y;
          
          segments.push({ x1, y1, x2, y2 });
        }
        
        return segments;
    }

      // Draw an arrow on a segment
    public drawArrow(ctx: any, x: any, y: any, angle: any) {
        const arrowLength = 10;
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(x - arrowLength * Math.cos(angle - Math.PI / 6), y - arrowLength * Math.sin(angle - Math.PI / 6));
        ctx.moveTo(x, y);
        ctx.lineTo(x - arrowLength * Math.cos(angle + Math.PI / 6), y - arrowLength * Math.sin(angle + Math.PI / 6));
        ctx.strokeStyle = '#0000FF80';
        ctx.lineWidth = 2;
        ctx.stroke();
    }

    public drawPipeSegment(ctx: any, segment: any) {
        ctx.beginPath();
        ctx.moveTo(segment.x1, segment.y1);
        ctx.lineTo(segment.x2, segment.y2);
        ctx.strokeStyle = '#0000FF80';
        ctx.lineWidth = 5;
        ctx.stroke();
    }

    public getApiUrl() {
        return this.url;
    }

    parseFl4(response: any): any[] {
      const result = [];
    
      // Iterate over the content index (e.g., 'CUVSA') and its parameters
      for (const contentIndex in response) {
        if (response.hasOwnProperty(contentIndex)) {
          const label = response[contentIndex].label;
          const params = response[contentIndex].params;
    
          // Push an intermediate header object
          result.push({
            type: 'header',
            contentIndex: contentIndex, // e.g., 'CUVSA'
            label: label                // e.g., 'Some Label'
          });
    
          // Push each parameter for this content index
          for (const paramKey in params) {
            if (params.hasOwnProperty(paramKey)) {
              const param = params[paramKey];
              result.push({
                type: 'param',
                index: contentIndex,
                title: param.title,       // e.g., 'Parameter 1'
                value: param.value,       // e.g., 'Value 1'
                unit: param.unit,         // e.g., 'Unit 1'
                editable: param.editable  // e.g., true/false
              });
            }
          }
        }
      }
    
      return result;
    }    

    public simulateFrame(data: any, site: any) {
      const formData = new FormData();
      formData.append('idClient', site.name);
      formData.append('idTermod', site.ref);
      formData.append('Data', data);
      this.httpClient.post( this.getApiUrl() + '/api/frame', formData, {withCredentials: true})
        .subscribe(response => {
          console.log('Request successful:', response);
        }, error => {
          console.error('Request failed:', error);
        });
    }
}